// const BASE_URL = REACT_APP_LOCAL_API_URL; // Your API base URL
const BASE_URL = process.env.REACT_APP_HOST_API_URL;
// const BASE_URL = process.env.REACT_APP_URL_HOST_API_URL

// Function to make a POST request to submit form data
export const sendMail = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/user/send-mail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    return response.json(); // Parse response JSON
  } catch (error) {
    throw new Error("Failed to submit form data"); // Handle error
  }
};
