import React from "react";
import { Carousel } from "primereact/carousel";

const CarouselSlider = (props) => {
  const { items, itemTemplate, chooseUs } = props;
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <div
      className={`${
        chooseUs ? "chooseUs-carousel-container" : "carousel-container"
      }`}
    >
      <Carousel
        value={items}
        itemTemplate={itemTemplate}
        numVisible={1}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        autoplayInterval={3000}
        circular
        autoplay
        contentClassName={`${
          chooseUs ? "chooseUs-carousel-content" : "carousel-content"
        }`}
      />
    </div>
  );
};

export default CarouselSlider;
