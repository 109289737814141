import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_API_URL;
  const BASE_URL = process.env.REACT_APP_HOST_API_URL;

  const [email, setEmail] = useState("");

  const addSubscriber = async () => {
    try {
      await axios
        .post(`${BASE_URL}/user/subscriber/addSubscriber`, { email })
        .then((response) => {
          const { success } = response?.data;
          if (success !== false) {
            setEmail("");
            toast.success("Congratulations, You've successfully subscribed!");
          }
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await addSubscriber(email);
  };
  return (
    <>
      {/* <footer id="footer" class="footer">
        <div class="container footer-top">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-about">
              <Link to="index.html" class="logo d-flex align-items-center">
                <span>R-Mak Tech Solutions</span>
              </Link>
              <p>
                Cras fermentum odio eu feugiat lide par naso tierra. Justo eget
                nada terra videa magna derita valies darta donna mare fermentum
                iaculis eu non diam phasellus.
              </p>
              <div class="social-links d-flex mt-4">
                <Link to="">
                  <i class="bi bi-twitter"></i>
                </Link>
                <Link to="">
                  <i class="bi bi-facebook"></i>
                </Link>
                <Link to="">
                  <i class="bi bi-instagram"></i>
                </Link>
                <Link to="">
                  <i class="bi bi-linkedin"></i>
                </Link>
              </div>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <Link to="/product">Product</Link>
                </li>
                <li>
                  <Link to="/services/software">Software</Link>
                </li>
                <li>
                  <Link to="/services/security">Security</Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 footer-contact text-md-start">
              <h4>Contact Us</h4>
              <p>A108 Adam Street</p>
              <p>New York, NY 535022</p>
              <p>United States</p>
              <p class="mt-4">
                <strong>Phone:</strong> <span>+1 5589 55488 55</span>
              </p>
              <p>
                <strong>Email:</strong> <span>info@example.com</span>
              </p>
            </div>
          </div>
        </div>

        <div class="container copyright text-center mt-4">
          <p>
            &copy; <span>Copyright</span>{" "}
            <strong class="px-1">R-Mak Tech Solutions</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
          <div class="credits">
            Designed by{" "}
            <Link to="https://bootstrapmade.com/">MappingNinja Solutions</Link>
          </div>
        </div>
      </footer> */}
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-contact">
                <h3>R-Mak Tech Solutions</h3>
                <p>
                  Basant Colony, Ukala Road, <br />
                  Ratlam, MP, 457001
                  <br />
                  India <br />
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+919201234512">&nbsp;+91 9201234512</a>
                  <br />
                  <strong>Email:</strong>
                  <a href="mailto:info@rmak.co.in">&nbsp;info@rmak.co.in</a>
                  <br />
                </p>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i> <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/services">Services</Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/product">Product</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/services/software">Software</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/services/security">Security</Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Join Our Newsletter</h4>
                <p>
                  Subscribe our newsletter for getting latest updates on your
                  email
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="container d-md-flex py-4">
          <div class="me-md-auto text-center text-md-start">
            <div class="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>R-MAK Tech Solutions</span>
              </strong>{" "}
              All Rights Reserved
            </div>
          </div>
          {/* <div class="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="#" class="twitter">
              <i class="bx bxl-twitter"></i>
            </a>
            <a href="#" class="facebook">
              <i class="bx bxl-facebook"></i>
            </a>
            <a href="#" class="instagram">
              <i class="bx bxl-instagram"></i>
            </a>
            <a href="#" class="google-plus">
              <i class="bx bxl-skype"></i>
            </a>
            <a href="#" class="linkedin">
              <i class="bx bxl-linkedin"></i>
            </a>
          </div> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
