import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Navbar from "./components/navbar";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services/services";
import Product from "./pages/product";
import Software from "./pages/services/software";
import Contact from "./pages/contact";
import Security from "./pages/services/security";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <Router>
      <HelmetProvider>
        <ToastContainer />
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route key="home" exact path="/" element={<Home />} />
          <Route key="about" exact path="/about" element={<About />} />
          <Route key="services" exact path="/services" element={<Services />} />
          <Route key="hardware" exact path="/product" element={<Product />} />
          <Route
            key="software"
            exact
            path="/services/software"
            element={<Software />}
          />
          <Route
            key="security"
            exact
            path="/services/security"
            element={<Security />}
          />
          <Route key="contact" exact path="/contact" element={<Contact />} />
        </Routes>
      </HelmetProvider>
    </Router>
  );
}

export default App;
