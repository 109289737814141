import React, { useState } from "react";

const SoftwarePricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState("");
  const [encodedMessage, setEncodedMessage] = useState("");

  const openModal = (modalContent) => {
    setShowModal(true);
    const cleanedContent = modalContent.replace(/\s+/g, " ").trim(); // Replace consecutive spaces with a single space and trim leading/trailing spaces
    setContent(cleanedContent);
    const encodedMsg = encodeURIComponent(
      cleanedContent.replace(/<br>/g, "\n")
    );
    setEncodedMessage(encodedMsg);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <section id="pricing" className="pricing">
        <div className="container" data-aos="fade-up">
          <div className="section-title" data-aos="zoom-out">
            <h2>Our Plans</h2>
            <p>
              Every Business Deserves Quality Software Service in Best Pricing
            </p>
          </div>

          <div className="row gy-4" data-aos="fade-left">
            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="box">
                <h3 style={{ color: "#07d5c0" }}>Free Plan</h3>
                <div className="price">
                  <sup>₹</sup>0
                </div>
                <img
                  src="/assets/img/pricing/pricing-8.jpg"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Free Consultant</li>
                  <li>Call Support</li>
                  <li>Email Support</li>
                  <li>Free Guidence</li>
                  <li>Virtual Assistant</li>
                </ul>
                <a
                  className="btn btn-buy"
                  onClick={() =>
                    openModal(`Hello, R-Mak TechSolutions<br><br>
      I hope this message finds you well. I'm reaching out to inquire about the services you offer. Could you please provide more information on how these services can benefit me or my business? I look forward to hearing from you soon.<br><br>
      Thank you!`)
                  }
                >
                  Buy Now
                </a>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="box">
                <span className="featured">starter Plan</span>
                <h3 style={{ color: "#65c600" }}>Website</h3>
                <div className="price">
                  <sup>₹</sup>5999
                </div>
                <img
                  src="/assets/img/pricing/pricing-5.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Responsive Website</li>
                  <li>Custom Design</li>
                  <li>Upto 3 Pages</li>
                  <li>Call support</li>
                  <li>Domain + Hosting</li>
                </ul>
                <a
                  className="btn btn-buy"
                  onClick={() =>
                    openModal(`Hello, R-Mak TechSolutions<br><br>
      I'm interested in your website services. I need a responsive website with custom design for up to 3 pages. Do you provide call support? Also, I'd like to know if your package includes domain and hosting. Looking forward to hearing from you soon. <br><br>
      Thank you!`)
                  }
                >
                  Buy Now
                </a>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="box">
                <h3 style={{ color: "#ff901c" }}>Mobile App</h3>
                <div className="price">
                  <sup>₹</sup>7999
                </div>
                <img
                  src="/assets/img/pricing/pricing-4.jpeg"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Scalable App</li>
                  <li>Custom Design </li>
                  <li>Upto 5 pages</li>
                  <li>Call Support</li>
                  <li className="na">Domain + Hosting</li>
                </ul>
                <a
                  className="btn btn-buy"
                  onClick={() =>
                    openModal(`Hello, R-Mak TechSolutions<br><br>
     I'm interested in your scalable app development services with custom design. I'm looking to create a website consisting of up to 5 pages. Additionally, I'd like to inquire about your call support and hosting options. Could you please provide more details regarding pricing and availability? <br><br>
      Thank you!`)
                  }
                >
                  Buy Now
                </a>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="box">
                <h3 style={{ color: "#ff0071" }}>Ultimate Plan</h3>
                <div className="price">
                  <sup>₹</sup>14999
                </div>
                <img
                  src="/assets/img/pricing/pricing-7.gif"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Website</li>
                  <li>Mobile App</li>
                  <li>Custom Web App</li>
                  <li>Responsiveness & Scalability</li>
                  <li>Call Support</li>
                </ul>
                <a
                  className="btn btn-buy"
                  onClick={() =>
                    openModal(`Hello, R-Mak TechSolutions<br><br>
     I'm interested in your services for website and mobile app development, as well as custom web applications. Can you please provide more information regarding responsiveness, scalability, and available call support?<br><br>
      Thank you!`)
                  }
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <>
          <div className="modal-overlay"></div>

          <div
            className={`modal fade ${showModal ? "show" : ""}`}
            id="EnquiryModal"
            tabIndex="-1"
            role="dialog"
            style={{ display: showModal ? "block" : "none" }}
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Begin Your Enquiry Today
                  </h5>
                  <i
                    class="bi bi-x-lg"
                    style={{ color: "white" }}
                    onClick={closeModal}
                  ></i>
                </div>
                <div class="modal-body">
                  <p dangerouslySetInnerHTML={{ __html: content }}></p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <a
                    type="button"
                    class="btn btn-primary"
                    href={`https://wa.me/+919201234512?text=${encodedMessage}`}
                    target="_blank"
                  >
                    Send Enquiry
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SoftwarePricing;
