import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import EnquireForm from "./enquiryForm";

const EnquiryModal = ({ isOpen, toggle, serviceSelected }) => {
  return (
    // <>
    //   {showModal && (
    //     <>
    //       <div className="modal-overlay"></div>
    //       <div
    //         className={`modal fade ${showModal ? "show" : ""}`}
    //         id="EnquiryModal"
    //         tabIndex="-1"
    //         role="dialog"
    //         style={{ display: showModal ? "block" : "none" }}
    //       >
    //         <div className="modal-dialog " role="document">
    //           <div className="modal-content">
    //             <div className="modal-header">
    //               <h5 className="modal-title">Your Enquiry</h5>
    //               <i
    //                 class="bi bi-x-lg"
    //                 style={{ color: "white" }}
    //                 onClick={closeModal}
    //               ></i>
    //             </div>
    //             <div
    //               className="modal-body row"
    //               style={{
    //                 maxHeight: "20%",
    //                 overflowY: "auto",
    //                 overflow: "hidden !important",
    //               }}
    //             >
    //               <div className="modal-form-container">
    //                 <form>
    //                   <div className="form-group">
    //                     <label htmlFor="name" className="col-form-label">
    //                       Name:
    //                     </label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="name"
    //                       value={state?.name}
    //                       onChange={(e) => setState({ name: e?.target?.value })}
    //                       onFocus={() => setState({ nameError: "" })}
    //                       onBlur={validateName}
    //                       placeholder="Your name.."
    //                     />
    //                     {state?.nameError ? (
    //                       <small className="text-danger ml-2">
    //                         {state.nameError}
    //                       </small>
    //                     ) : null}
    //                   </div>
    //                   <div className="form-group">
    //                     <label htmlFor="email" className="col-form-label">
    //                       Email:
    //                     </label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="email"
    //                       value={state?.email}
    //                       onChange={(e) =>
    //                         setState({ email: e?.target?.value })
    //                       }
    //                       onFocus={() => setState({ emailError: "" })}
    //                       onBlur={validateEmail}
    //                       placeholder="Your email.."
    //                     />
    //                     {state?.emailError ? (
    //                       <small className="text-danger ml-2">
    //                         {state.emailError}
    //                       </small>
    //                     ) : null}
    //                   </div>
    //                   <div className="form-group">
    //                     <label htmlFor="phone" className="col-form-label">
    //                       Phone Number:
    //                     </label>
    //                     <PhoneInput
    //                       placeholder="Enter phone number"
    //                       value={state?.phone}
    //                       onChange={(e) =>
    //                         setState({ phone: e?.target?.value })
    //                       }
    //                       onFocus={() => setState({ phoneError: "" })}
    //                       onBlur={validatePhoneNumber}
    //                       country={"in"}
    //                       inputStyle={{ width: "100%" }}
    //                       enableSearch={true}
    //                       required
    //                     />
    //                     {state?.phoneError ? (
    //                       <small className="text-danger ml-2">
    //                         {state.phoneError}
    //                       </small>
    //                     ) : null}
    //                   </div>
    //                   <div className="form-group">
    //                     <label htmlFor="service" className="col-form-label">
    //                       Choosed Service:
    //                     </label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="service"
    //                       disabled
    //                       value={serviceSelected}
    //                     />
    //                   </div>

    //                   {window.location.pathname === "/product" &&
    //                     (serviceSelected === "E-Learning Devices" ||
    //                       serviceSelected === "Conference Device" ||
    //                       serviceSelected === "Scanning Device") && (
    //                       <div className="form-group">
    //                         <label htmlFor="service" className="col-form-label">
    //                           Choosed Sub Service:
    //                         </label>
    //                         <div className="dropdown">
    //                           <button
    //                             className="btn btn-outline-secondary  w-100  d-flex justify-content-between align-items-center dropdown-button"
    //                             type="button"
    //                             id="dropdownMenuButton"
    //                             data-toggle="dropdown"
    //                             aria-haspopup="true"
    //                             aria-expanded="false"
    //                             style={{ boxShadow: "none" }}
    //                           >
    //                             <span>{selectedSubOption}</span>
    //                           </button>
    //                           <div
    //                             className="dropdown-menu"
    //                             aria-labelledby="dropdownMenuButton"
    //                           >
    //                             {options.map((option, index) => (
    //                               <a
    //                                 key={index}
    //                                 className="dropdown-item"
    //                                 onClick={() => handleOptionClick(option)}
    //                               >
    //                                 {option}
    //                               </a>
    //                             ))}
    //                             {/* Add more options as needed */}
    //                           </div>
    //                         </div>
    //                       </div>
    //                     )}

    //                   <div className="form-group">
    //                     <label htmlFor="message" className="col-form-label">
    //                       Message:
    //                     </label>
    //                     {/* <ReactQuill
    //                       theme="snow" // Set the theme
    //                       value={message}
    //                       onChange={(value) =>
    //                         console.log("message", value?.text)
    //                       }
    //                       style={{ height: "200px" }}
    //                     /> */}
    //                     <textarea
    //                       className="message-textarea"
    //                       value={state?.message}
    //                       onChange={(e) =>
    //                         setState({ message: e?.target?.value })
    //                       }
    //                     />
    //                   </div>
    //                 </form>
    //               </div>
    //             </div>
    //             <div className="modal-footer">
    //               <button
    //                 type="button"
    //                 className="btn btn-secondary"
    //                 onClick={closeModal}
    //               >
    //                 Close
    //               </button>
    //               <button
    //                 type="button"
    //                 className="btn btn-primary"
    //                 onClick={handleSubmit}
    //               >
    //                 Send message
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   )}
    // </>
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Your Enquiry</ModalHeader>
      <ModalBody className="px-4">
        <EnquireForm serviceSelected={serviceSelected} toggle={toggle} />
      </ModalBody>
    </Modal>
  );
};

export default EnquiryModal;
