import React from "react";
import PageHeroSection from "../../components/pageHeroSection";
import SecurityService from "../../components/securityService";
import Footer from "../../components/footer";
import SecurityPortfolio from "../../components/securityPortfolio";

const Security = () => {
  const title = [
    "Security Services",
    "CCTV Camera",
    "BioMetric Devices",
    "FireAlarm",
  ];
  return (
    <div>
      <PageHeroSection
        title={title}
        description="We provide security devices to prevent crimes against persons, property and proprietary information. Security provides a safe and threat-free environment, so that people can go about their daily work and business without any fear."
        linkto="securityService"
      />
      <SecurityService />
      <SecurityPortfolio />
      <Footer />
    </div>
  );
};

export default Security;
