import React from "react";

const AboutService = () => {
  return (
    <div>
      <section id="aboutService" class="aboutService">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row align-items-xl-center gy-5">
            <div class="col-xl-5 content">
              <h3>Technologies </h3>
              <h2>We use Variety of technologies to develop your projects</h2>
              <p>
                We leverage a diverse range of cutting-edge technologies to
                bring your projects to life. From robust backend frameworks to
                sleek frontend libraries, our team harnesses the power of
                innovation to deliver exceptional solutions tailored to your
                unique needs.
              </p>
              <a href="#detailService" class="read-more">
                <span>Get Started</span>
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>

            <div class="col-xl-7">
              <div class="row gy-4 icon-boxes">
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <div class="icon-box">
                    {/* <i class="bi bi-buildings"></i> */}
                    <img
                      src="/assets/img/software/htmlcssjs.png"
                      alt="htmlcss"
                    />
                    <h3>HTML, CSS & Javascript</h3>
                    <p>
                      Our comprehensive service ensures seamless integration of
                      these technologies, enabling clients to engage their
                      audience effectively and stay ahead in the digital
                      landscape.
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
                  <div class="icon-box">
                    {/* <i class="bi bi-clipboard-pulse"></i> */}
                    <img src="/assets/img/software/php.png" alt="js" />
                    <h3>PHP</h3>
                    <p>
                      Whether you're building a simple blog or a complex
                      e-commerce platform, PHP offers the flexibility and
                      scalability to bring your ideas to life efficiently.
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
                  <div class="icon-box">
                    {/* <i class="bi bi-command"></i> */}
                    <img src="/assets/img/software/mernstack.png" alt="mern" />

                    <h3>MERN Stack</h3>
                    <p>
                      With MERN stack, businesses can leverage the latest web
                      development trends to deliver high-performance solutions
                      tailored to their specific needs.
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
                  <div class="icon-box">
                    {/* <i class="bi bi-graph-up-arrow"></i> */}
                    <img src="/assets/img/software/flutter.png" alt="flutter" />
                    <h3>Flutter</h3>
                    <p>
                      With its rich set of pre-designed widgets and fast
                      development cycles, Flutter enables developers to craft
                      beautiful and highly performant apps across multiple
                      platforms with ease.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutService;
