import React, { useState } from "react";
import EnquiryModal from "./modal";

const SecurityService = () => {
  const [showModal, setShowModal] = useState(false);
  const [serviceSelected, setServiceSelected] = useState("");

  const securityServices = [
    {
      title: "CCTV Camera",
      description:
        "We specialize in providing top-quality CCTV camera solutions tailored to meet your security needs. Whether for residential, commercial, or industrial purposes, our expert team is dedicated to delivering reliable surveillance solutions to protect what matters most to you.",
      image: "/assets/img/Security Devices/CCTV Camera/CCTV.jpg",
      alt: "CCTV camera",
    },
    {
      title: "Fire Alarm",
      description:
        "With our advanced technology and expert team, we ensure optimal safety and protection against fire hazards. From design to installation and maintenance, we deliver comprehensive solutions that prioritize safety and peace of mind for our customers.",
      image: "/assets/img/Security Devices/Fire Alarm/fire-alarm-system.jpg",
      alt: "Fire Alarm",
    },

    {
      title: "GPS Tracking Device",
      description:
        "Our state-of-the-art technology enables businesses and individuals to track vehicles, assets, and personnel in real-time, ensuring security, efficiency. With our reliable and user-friendly solutions, you can stay connected and in control, no matter where your assets are located.",
      image: "/assets/img/Security Devices/Biometric/GPS DEVICE.jpg",
      alt: "GPS Tracking Device",
    },
    // {
    //   title: "Face Detection System",
    //   description:
    //     "Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cu delectus doloribus non veritatis. Officia temporibus illo magnam. Dolor eos et.",
    //   image: "/assets/img/Security Devices/Biometric/bio matric machine.jpg",
    //   alt: "Face Detection System",
    // },
    {
      title: "Smart Digital Lock",
      description:
        "Whether for residential, commercial, or industrial applications, our digital locks redefine security standards, ensuring seamless integration and uncompromising protection for your valuable assets.",
      image:
        "/assets/img/Security Devices/Biometric/smart-door-lock-system.jpeg",
      alt: "Digital Lock System",
    },
    // {
    //   title: "Motion Detector System",
    //   description:
    //     "Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cu delectus doloribus non veritatis. Officia temporibus illo magnam. Dolor eos et.",
    //   image: "/assets/img/Security Devices/Biometric/bio matric machine.jpg",
    //   alt: "Motion Detector System",
    // },
  ];

  const openModal = (service) => {
    setShowModal(true);
    setServiceSelected(service);
  };

  return (
    <>
      <section id="securityService" class="securityService section-bg">
        <div class="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Offerings</h2>
          </div>

          <div class="row gy-4">
            {securityServices.map((securityService, index) => (
              <div
                class="col-lg-6"
                data-aos="fade-up"
                data-aos-delay={100 * (index + 1)}
              >
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-5">
                      <div class="card-bg">
                        <img
                          src={securityService.image}
                          alt={securityService.alt}
                        />
                      </div>
                    </div>
                    <div class="col-xl-7 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">{securityService.title}</h4>
                        <p>{securityService.description}</p>
                        <button
                          className="readmore stretched-link"
                          onClick={() => openModal(securityService.title)}
                        >
                          Enquiry Now <i className="bi bi-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <EnquiryModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        serviceSelected={serviceSelected}
      />
    </>
  );
};

export default SecurityService;
