import React, { useEffect, useState } from "react";

import CarouselSlider from "./carouselSlider";
import { Typewriter } from "react-simple-typewriter";
import axios from "axios";

const PageHeroSection = (props) => {
  // const [offerImages, setOfferImages] = useState([]);
  const { title, description, linkto, productPage } = props;
  // const BASE_URL = process.env.REACT_APP_LOCAL_API_URL;
  // const BASE_URL = process.env.REACT_APP_HOST_API_URL;

  const offerImages = [
    "/assets/img/offers/offer2.jpg",
    "/assets/img/offers/offer3.jpg",
    "/assets/img/offers/offer4.jpg",
    "/assets/img/offers/offer5.jpg",
    "/assets/img/offers/offer6.jpg",
  ];

  const renderOffer = (offer, index) => {
    return (
      <div
        className="card carousel-card-slider"
        key={index}
        style={{ marginRight: "20px" }}
      >
        {/* <img src={offer?.imageUrl} alt="productImage" /> */}
        <img src={offer} alt={`productImage ${index}`} />
      </div>
    );
  };

  // const getAllOffer = async () => {
  //   try {
  //     const {
  //       data: { success, data },
  //     } = await axios.get(`${BASE_URL}/admin/offer/getAllOffer`);

  //     if (success !== false) {
  //       console.log("inside");
  //       setOfferImages(data);
  //     }
  //     return false;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  const currentDate = new Date();

  // Filter items based on start and end dates
  // const filteredOfferImage = offerImages.filter((item) => {
  //   console.log("🚀 ~ filteredOfferImage ~ item:", item);
  //   const startDate = new Date(item?.startDate);
  //   const endDate = new Date(item?.endDate);
  //   return startDate <= currentDate && endDate >= currentDate;
  // });

  // useEffect(() => {
  //   getAllOffer();
  // }, []);

  return (
    <>
      <section
        id="hero"
        className={`${
          productPage ? "product-page-container" : "hero-container"
        }`}
        style={{ position: "relative" }}
      >
        <div className="container-fluid" style={{ padding: 0 }}>
          <div
            className="hero-section container page-container"
            data-aos="fade-up"
          >
            <div className="title-container" style={{ height: "103px" }}>
              <h2 className="title text-center">
                <Typewriter
                  words={title}
                  loop={true}
                  cursor
                  cursorStyle=""
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </h2>
            </div>

            <p className="animate__animated fanimate__adeInUp">{description}</p>
            <a
              href={`#${linkto}`}
              className="btn-get-started animate__animated animate__fadeInUp"
            >
              Read More
            </a>
          </div>
        </div>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
      {window.location.pathname === "/product" && (
        <CarouselSlider
          // items={filteredOfferImage}
          items={offerImages}
          itemTemplate={renderOffer}
          chooseUs={false}
        />
      )}
    </>
  );
};

export default PageHeroSection;
