import React, { useState } from "react";
import PageHeroSection from "../components/pageHeroSection";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import { sendMail } from "../api/sendMail";

const Contact = () => {
  const title = ["Contact Us"];
  const initialState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    isDisabled: false,
    isEnquiryMail: false,
    service: "Contact",
    subService: "Email",
  };
  const [state, setState] = useState(initialState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("state", state);

    setState({ ...state, isDisabled: true });

    try {
      const response = await sendMail(state);
      if (response.status === 200) {
        toast.success(response?.message || "Form submitted successfully!");
        setState({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        toast.error(
          response?.message || "Failed to submit form. Please try again later."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setState({ ...state, isDisabled: false });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <PageHeroSection
        title={title}
        active="active"
        description="Have a question about our products? Need assistance with an order? Our dedicated team is here to help. Contact us via email, phone, or visit our showroom to experience our products firsthand."
        productPage={false}
        linkto="contact"
      />
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Our Address</h3>
                    <p>Basant Colony, Ukala Road, Ratlam, MP, 457001</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>info@rmak.co.in</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>+91 9201234512</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form
                role="form"
                className="php-email-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      value={state.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      value={state.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    value={state.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    value={state.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" disabled={state.isDisabled}>
                    {state.isDisabled ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
