import React from "react";

import PageHeroSection from "../components/pageHeroSection";
import Footer from "../components/footer";
import CountUp from "react-countup";
import MVV from "../components/mvv";
import Clients from "../components/clients";
// import ServiceSection from "../components/serviceSection";
import CarouselSlider from "../components/carouselSlider";
// import Teams from "../components/teams";

const About = () => {
  const chooseUsitems = [
    {
      title: "Innovative Solutions",
      subtitle: "Leading the Way in Technological Advancements",
      description:
        "At RMAK Tech Solutions, we are committed to pushing the boundaries of innovation. Our team of experts is constantly exploring new technologies, methodologies, and approaches to deliver solutions that are not just ahead of the curve, but also redefine industry standards. With our innovative solutions, you can stay ahead of the competition and drive your business forward with confidence.",
    },
    {
      title: "Customer-Centric Approach",
      subtitle: "Your Success is Our Priority",
      description:
        "Our customer-centric approach is at the heart of everything we do. We believe in listening to our clients, understanding their needs, and aligning our solutions to help them achieve their goals. From the initial consultation to post-project support, we are dedicated to providing an exceptional experience that exceeds your expectations and fosters long-term partnerships built on trust and mutual success.",
    },
    {
      title: "Proven Track Record",
      subtitle: "Delivering Excellence, Every Time",
      description:
        "With a proven track record of successful projects and satisfied clients, RMAK Tech Solutions has established itself as a trusted leader in the technology industry. Our portfolio showcases a diverse range of projects across various sectors, highlighting our expertise, reliability, and commitment to delivering excellence. When you choose us, you can trust that you're in capable hands.",
    },
    // {
    //   title: "Commitment to Quality",
    //   subtitle: "Setting the Standard for Excellence",
    //   description:
    //     "Quality is at the core of everything we do. We adhere to the highest standards of quality assurance and testing to ensure that our solutions are robust, reliable, and bug-free. Our commitment to quality ensures that your project is delivered on time, within budget, and meets the highest standards of excellence.",
    // },
    // {
    //   title: "Transparent Communication",
    //   subtitle: "Keeping You Informed Every Step of the Way",
    //   description:
    //     "Communication is key to successful partnerships, which is why we believe in open and transparent communication throughout the project lifecycle. We keep you informed with regular updates on project progress, milestones, and any potential challenges. Your feedback is valuable to us, and we welcome it at every stage of the project.",
    // },
  ];

  const chooseUsItemTemplate = (item) => {
    return (
      <div className="item">
        <h3 className="mb-3">{item.title}</h3>
        <h4 className="mb-3">{item.subtitle}</h4>
        <p>{item.description}</p>
      </div>
    );
  };

  const title = ["About Us"];
  return (
    <>
      <PageHeroSection
        title={title}
        description="At R-MAK TECH SOLUTION, we are dedicated to enhancing your digital experience through cutting-edge computer peripherals. Established in 2024, we have been at the forefront of innovation, providing high-quality products that cater to the needs of gamers, professionals, and enthusiasts alike.
Empower your business with our comprehensive software services tailored to your needs. From crafting stunning websites and intuitive mobile apps to creating captivating logos, we offer end-to-end solutions to elevate your online presence. Let us guide you through the digital landscape and unlock the full potential of your business in the online world."
        linkto="alt-services"
      />

      {/* <ServiceSection /> */}

      <section id="counts" class="counts">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-emoji-smile"></i>
                <CountUp end={80} />
                <p>Happy Clients</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div class="count-box">
                <i class="bi bi-journal-richtext"></i>
                <CountUp end={110} />
                <p>Projects</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="bi bi-headset"></i>
                <CountUp end={24 * 7} />
                <p>Hours Of Support</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div class="count-box">
                <i class="bi bi-people"></i>
                <CountUp end={50} />
                <p>Team Members</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div className="section-title" data-aos="zoom-out">
            <h2>About</h2>
            <p>Why Choose Us</p>
          </div>

          <div class="row g-0" data-aos="fade-up" data-aos-delay="200">
            <div class="col-xl-5 img-bg">
              <img
                src="assets/img/Why-choose-us-1.png"
                alt="why-choose-us"
                class="img-fluid"
              />
            </div>
            <div class="col-xl-7 slides ">
              <CarouselSlider
                items={chooseUsitems}
                itemTemplate={chooseUsItemTemplate}
                chooseUs={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="featuresSection section-bg mt-4">
        <div className="container">
          <div className="section-title" data-aos="zoom-out">
            <h2>Our</h2>
            <p>Vision Mission Values</p>
          </div>
          <MVV />
        </div>
      </section>

      {/* <Teams /> */}

      <section className="clientsSections">
        <div className="container">
          <div className="section-title" data-aos="zoom-out">
            <h2>Our</h2>
            <p>Clients</p>
          </div>
          <Clients />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
