import React from "react";
import PageHeroSection from "../../components/pageHeroSection";
import AboutService from "../../components/aboutService";
import Footer from "../../components/footer";
import DetailService from "../../components/detailService";
import SoftwarePricing from "../../components/softwarePricing";

const Software = () => {
  const title = [
    "Software Services",
    "Website Development",
    "App Development",
    "MERN Stack Developer",
    "Logo Design",
    "Backend Developer",
  ];
  return (
    <>
      <PageHeroSection
        title={title}
        description="Empower your business with our comprehensive software services tailored to your needs. From crafting stunning websites and intuitive mobile apps to creating captivating logos, we offer end-to-end solutions to elevate your online presence. Let us guide you through the digital landscape and unlock the full potential of your business in the online world."
        linkto="aboutService"
      />
      <AboutService />
      <DetailService />
      <SoftwarePricing />
      <Footer />
    </>
  );
};

export default Software;
