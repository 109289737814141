import React from "react";
import PageHeroSection from "../../components/pageHeroSection";
import Footer from "../../components/footer";

const Services = () => {
  return (
    <>
      <PageHeroSection
        title="Services"
        description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Et cumque dicta consectetur quisquam nisi doloribus, optio beatae enim eligendi nam saepe sunt corporis veritatis fuga impedit consequatur similique! At facere doloremque nemo!
"
        linkto="about-us"
      />
      <section id="about-us" class="about-us">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="about-us-img">
                <img src="assets/img/features-2.png" alt="" class="img-fluid" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-us-content">
                <p>
                  Molestiae omnis numquam corrupti omnis itaque. Voluptatum
                  quidem impedit. Odio dolorum exercitationem est error omnis
                  repudiandae ad dolorum sit.
                </p>
                <p>
                  Explicabo repellendus quia labore. Non optio quo ea ut ratione
                  et quaerat. Porro facilis deleniti porro consequatur et
                  temporibus. Labore est odio. Odio omnis saepe qui. Veniam
                  eaque ipsum. Ea quia voluptatum quis explicabo sed nihil
                  repellat..
                </p>

                <div
                  class="features clearfix"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="bi bi-bookmarks" style={{ color: "#f058dc" }}></i>
                  <h4>Corporis dolorem</h4>
                  <p>
                    Commodi quia voluptatum. Est cupiditate voluptas quaerat
                    officiis ex alias dignissimos et ipsum. Soluta at enim modi
                    ut incidunt dolor et.
                  </p>
                </div>

                <div
                  class="features clearfix"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="bi bi-box-seam" style={{ color: "#ffb774" }}></i>
                  <h4>Eum ut aspernatur</h4>
                  <p>
                    Molestias eius rerum iusto voluptas et ab cupiditate aut
                    enim. Assumenda animi occaecati. Quo dolore fuga quasi autem
                    aliquid ipsum odit. Perferendis doloremque iure nulla aut.
                  </p>
                </div>

                <div
                  class="features clearfix"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i
                    class="bi bi-card-checklist"
                    style={{ color: "#589af1" }}
                  ></i>
                  <h4>Voluptates dolores</h4>
                  <p>
                    Voluptates nihil et quis omnis et eaque omnis sint aut.
                    Ducimus dolorum aspernatur. Totam dolores ut enim ullam
                    voluptas distinctio aut.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="servicetype" class="servicetype section-bg">
        <div class="container">
          <div className="section-title" data-aos="zoom-out">
            <h2>Our Services</h2>
            <p>Sofware</p>
          </div>
          <div
            class="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div class="col-md-5">
              <img src="assets/img/features-2.png" class="img-fluid" alt="" />
            </div>
            <div class="col-md-7">
              <h3>
                Voluptatem dignissimos provident quasi corporis voluptates sit
                assumenda.
              </h3>
              <p class="fst-italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex
                  ea commodo consequat.
                </li>
                <li>
                  <i class="bi bi-check"></i> Duis aute irure dolor in
                  reprehenderit in voluptate velit.
                </li>
                <li>
                  <i class="bi bi-check"></i> Ullam est qui quos consequatur eos
                  accusamus.
                </li>
              </ul>
            </div>
          </div>
          <div className="section-title text-end" data-aos="zoom-out">
            <p>Security</p>
          </div>
          <div
            class="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div class="col-md-5 order-1 order-md-2">
              <img src="assets/img/features-2.png" class="img-fluid" alt="" />
            </div>
            <div class="col-md-7 order-2 order-md-1">
              <h3>Corporis temporibus maiores provident</h3>
              <p class="fst-italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Services;
