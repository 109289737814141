import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Dropdown from "react-bootstrap/Dropdown";

import { sendMail } from "../api/sendMail";
import useImageUpload from "../utils/hooks/useFirebase";

const EnquireForm = ({ serviceSelected, toggle }) => {
  const intialState = {
    isEnquiryMail: true,
    service: serviceSelected,
    subject: "Have A Product Enquiry",
  };
  const [state, setCompleteState] = useState(intialState);
  const setState = (newState) =>
    setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const { error, uploadImage } = useImageUpload();

  const subServiceOptions = {
    "E-Learning Devices": ["N Computing", "Smart Boards", "Tabs", "Projectors"],
    "Conference Device": ["Projectors", "Web Cams", "Microphone", "Speakers"],
    "Scanning Device": ["Finger", "Face", "BarCode"],
  };

  const options = subServiceOptions[state?.service];

  const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const formedMessage = `
        Hello R-Mak TechSolutions,
        I hope this message finds you well. I'm reaching out because I'm keenly interested in your services and would appreciate more information.

    Here are my details:
        *Name:* ${state?.name}
        *Email:* ${state?.email}
        *Phone Number:* ${state?.phone}
        *Service Selected:* ${state?.service}
        *Message:* ${state?.message}

    Thank You
      `;

  const validateName = () => {
    if (!state?.name) {
      setState({ nameError: "*Enter name" });
    }
  };

  const validateMessage = () => {
    if (!state?.message) {
      setState({ messageError: "*Enter message" });
    }
  };

  const validateEmail = () => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(state?.email);

    if (!state?.email || !isValidEmail) {
      setState({ emailError: "*Please enter a valid email!" });
    }
  };

  const validatePhoneNumber = () => {
    if (!state?.phone) {
      setState({ phoneError: "*Provide valid numbers" });
    }
  };

  const handleImageChange = async (e) => {
    const file = e?.target?.files;
    const selectedFilesArray = Array.from(file);
    if (selectedFilesArray) {
      const imageUrls = await uploadImage(selectedFilesArray);
      setState({ refImage: imageUrls[0] });
    }
  };

  const sendMessage = async () => {
    try {
      const response = await sendMail(state);

      // Check if the email was sent successfully
      if (response.status === 200) {
        toggle();

        // Encode the message for the WhatsApp URL
        const encodedMessage = encodeURIComponent(formedMessage);
        window.open(
          `https://wa.me/+919201234512?text=${encodedMessage}`,
          "_blank"
        ); // Open WhatsApp with the encoded message

        toast.success(response?.message || "Enquiry sent successfully!");
      } else {
        toast.error(
          response?.message || "Failed to send enquiry. Please try again later."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const isFormValid = () => {
    // Check if all fields are valid
    return (
      !state?.nameError &&
      !state?.emailError &&
      !state?.phoneError &&
      !state?.messageError &&
      state?.name &&
      state?.email &&
      state?.phone &&
      state?.message
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if the form is valid
    if (isFormValid()) {
      sendMessage();
    } else {
      toast.error("Please fill all required fields");
    }
  };

  useEffect(() => {
    setState({ subService: "Select an Option" });
  }, [state?.service]);

  const handleOptionClick = (option) => {
    setState({ subService: option });
  };
  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="col-form-label">
              Name<span className="text-danger">*</span>&nbsp;:
            </label>

            <input
              type="text"
              className="form-control"
              id="name"
              value={state?.name}
              onChange={(e) => setState({ name: e?.target?.value })}
              onFocus={() => setState({ nameError: "" })}
              onBlur={validateName}
              placeholder="Your name.."
            />
            {state?.nameError ? (
              <small className="text-danger ml-2">{state.nameError}</small>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="email" className="col-form-label">
              Email<span className="text-danger">*</span>&nbsp;:
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              value={state?.email}
              onChange={(e) => setState({ email: e?.target?.value })}
              onFocus={() => setState({ emailError: "" })}
              onBlur={validateEmail}
              placeholder="Your email.."
            />
            {state?.emailError ? (
              <small className="text-danger ml-2">{state.emailError}</small>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="col-form-label">
              Phone Number<span className="text-danger">*</span>&nbsp;:
            </label>
            <PhoneInput
              placeholder="Enter phone number"
              value={state?.phone}
              onChange={(value) => setState({ phone: value })}
              onFocus={() => setState({ phoneError: "" })}
              onBlur={validatePhoneNumber}
              country={"in"}
              inputStyle={{ width: "100%" }}
              enableSearch={true}
              required
            />
            {state?.phoneError ? (
              <small className="text-danger ml-2">{state.phoneError}</small>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="service" className="col-form-label">
              Choosed Service:
            </label>
            <input
              type="text"
              className="form-control"
              id="service"
              disabled
              value={state?.service}
            />
          </div>
          {window.location.pathname === "/product" &&
            (state?.service === "E-Learning Devices" ||
              state?.service === "Conference Device" ||
              state?.service === "Scanning Device") && (
              <div className="form-group">
                <label htmlFor="service" className="col-form-label">
                  Choosed Sub Service:
                </label>

                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    {state?.subService || "Select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {options.map((option, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          <div className="form-group">
            <label htmlFor="message" className="col-form-label">
              Message<span className="text-danger">*</span>&nbsp;:
            </label>
            {/* <ReactQuill
                          theme="snow" Set the theme
                          value={message}
                          onChange={(value) =>
                            console.log("message", value?.text)
                          }
                          style={{ height: "200px" }}
                        /> */}
            <textarea
              className="message-textarea"
              value={state?.message}
              onFocus={() => setState({ messageError: "" })}
              onBlur={validateMessage}
              onChange={(e) => setState({ message: e?.target?.value })}
            />
            {state?.messageError ? (
              <small className="text-danger ml-2">{state?.messageError}</small>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="image" className="col-form-label">
              Upload Image
            </label>
            <input
              type="file"
              className="form-control"
              id="image"
              value={state?.image}
              onChange={handleImageChange}
              placeholder="Provide Reference Image (If any)"
              multiple
            />
            {state?.emailError ? (
              <small className="text-danger ml-2">{state.emailError}</small>
            ) : null}
          </div>
          <button id="btn" type="submit" className="btn btn-send">
            Send Enquiry
          </button>
        </form>
      </div>
    </>
  );
};

export default EnquireForm;
