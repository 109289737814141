import React from "react";
import PageHeroSection from "../components/pageHeroSection";
import Portfolio from "../components/portfolio";
import Footer from "../components/footer";
import HardwareCardFlip from "../components/hardwareCardFlip";
import ProductHeroSection from "../components/productHeroSection";

const Product = () => {
  const title = [
    "Our Product",
    "E-Learning Devices",
    "Conference Devices",
    "Scanning Devices",
    "Tablets",
    "Laptops",
    "Desktop",
  ];
  return (
    <>
      <PageHeroSection
        title={title}
        description="Explore our range of hardware solutions for education and business needs. From e-learning devices and scanning equipment to tablets, laptops, desktops, and conference devices, we provide reliable tools to enhance productivity and communication. Elevate your digital experience with our comprehensive hardware services."
        linkto="flipcardServices"
        productPage={true}
      />
      <HardwareCardFlip />
      <Portfolio />
      <Footer />
    </>
  );
};

export default Product;
