import React, { useRef, useEffect } from "react";
import Isotope from "isotope-layout";

const Portfolio = () => {
  const portfolioRef = useRef(null);
  const isotopeRef = useRef(null);

  const tabletImages = [
    {
      src: "/assets/img/hardware/Tablets/TABLETS.jpg",
      alt: "Tablets",
    },
  ];

  const elearningImages = [
    {
      src: "/assets/img/hardware/E-Learning/SMART BOARD.webp",
      alt: "Smart Board",
    },
    {
      src: "/assets/img/hardware/E-Learning/1 (1).jpg",
      alt: "Smart Board",
    },
    {
      src: "/assets/img/hardware/E-Learning/2.jpg",
      alt: "Smart Board",
    },
    {
      src: "/assets/img/hardware/E-Learning/3.jpg",
      alt: "Smart Board",
    },
    {
      src: "/assets/img/hardware/E-Learning/4.jpg",
      alt: "Smart Board",
    },
    {
      src: "/assets/img/hardware/E-Learning/5.jpg",
      alt: "Smart Board",
    },
  ];

  const conferenceImages = [
    {
      src: "/assets/img/hardware/Conference/CONFERENCE DEVICES.png",
      alt: "Conference Device",
    },
    {
      src: "/assets/img/hardware/Conference/MICROPONE.webp",
      alt: "Microphone",
    },
    {
      src: "/assets/img/hardware/Conference/PROJECTOR.jpg",
      alt: "Projector",
    },
    {
      src: "/assets/img/hardware/Conference/WEB CAMERS.jpg",
      alt: "Web Cameras",
    },
  ];

  const scanningImages = [
    {
      src: "/assets/img/hardware/Scanning/barc.jpeg",
      alt: "BarCode",
    },
    {
      src: "/assets/img/hardware/Scanning/Fingerprint-Sensors.png",
      alt: "FingerPrint Sensors",
    },
    {
      src: "/assets/img/hardware/Scanning/barcode.jpg",
      alt: "Bar Code",
    },
    {
      src: "/assets/img/hardware/Scanning/bio matric machine.jpg",
      alt: "Bar Code",
    },
  ];

  const laptopImages = [
    {
      src: "/assets/img/hardware/Laptops/laptop1.jpeg",
      alt: "Laptop",
    },
    {
      src: "/assets/img/hardware/Laptops/laptop2.jpeg",
      alt: "Laptop",
    },
    {
      src: "/assets/img/hardware/Laptops/laptop3.jpeg",
      alt: "Laptop",
    },
  ];

  const desktopImages = [
    {
      src: "/assets/img/hardware/Desktop/DESKTOP C.webp",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/DESKTOP.jpg",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/CPU (2).jpeg",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/CPU-03.jpeg",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/CPU.jpeg",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/HP PRO DESK ( DESKTOP).jpeg",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/TINY CPU.jpeg",
      alt: "Desktop",
    },
  ];

  const printerImages = [
    {
      src: "/assets/img/hardware/Printer/printer1.png",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer2.png",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer3.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer4.png",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer5.avif",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer6.avif",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer7.avif",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer8.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer9.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer10.webp",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer11.webp",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/printer12.webp",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/13.avif",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/14.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/15.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/16.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/17.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/18.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/19.jpg",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/20.webp",
      alt: "Printer",
    },
    {
      src: "/assets/img/hardware/Printer/EPSON 3210.PNG",
      alt: "Printer",
    },
  ];

  useEffect(() => {
    // Initialize Isotope
    isotopeRef.current = new Isotope(portfolioRef.current, {
      itemSelector: ".portfolio-item",
      layoutMode: "fitRows",
    });

    // Cleanup function
    return () => {
      // Destroy Isotope instance
      isotopeRef.current.destroy();
    };
  }, []);

  const handleFilterClick = (filterValue) => {
    const filterItems = document.querySelectorAll(".portfolio-flters li");
    filterItems.forEach((item) => {
      item?.classList?.remove("filter-active");
    });

    let clickedItem;
    if (filterValue === "*") {
      clickedItem = document.querySelector(".portfolio-flters li.all");
    } else {
      clickedItem = document.querySelector(
        `.portfolio-flters li${filterValue}`
      );
    }
    clickedItem?.classList?.add("filter-active");

    isotopeRef.current.arrange({ filter: filterValue });
  };

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title">
          <h2>Our Portfolio</h2>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ul id="portfolio-flters" className="portfolio-flters">
              <li
                onClick={() => handleFilterClick("*")}
                className="filter-active all"
              >
                All
              </li>
              <li
                className="filter-learning"
                onClick={() => handleFilterClick(".filter-learning")}
              >
                E-Learning
              </li>
              <li
                className="filter-conference"
                onClick={() => handleFilterClick(".filter-conference")}
              >
                Conference
              </li>
              <li
                className="filter-scanning"
                onClick={() => handleFilterClick(".filter-scanning")}
              >
                Scanning
              </li>
              <li
                className="filter-tablets"
                onClick={() => handleFilterClick(".filter-tablets")}
              >
                Tablets
              </li>
              <li
                className="filter-laptops"
                onClick={() => handleFilterClick(".filter-laptops")}
              >
                Laptops
              </li>
              <li
                className="filter-desktop"
                onClick={() => handleFilterClick(".filter-desktop")}
              >
                Desktop
              </li>
              <li
                className="filter-printer"
                onClick={() => handleFilterClick(".filter-printer")}
              >
                Printer
              </li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" ref={portfolioRef}>
          {/* E-Learning */}
          <div className="learning-container">
            {elearningImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-learning"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Conference */}
          <div className="conference-container">
            {conferenceImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-conference"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Scanning */}
          <div className="scanning-container">
            {scanningImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-scanning"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Tablets */}
          <div className="tablet-container">
            {tabletImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-tablets"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Laptops */}
          <div className="laptop-container">
            {laptopImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-laptops"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Desktop */}
          <div className="desktop-container">
            {desktopImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-desktop"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Printer */}
          <div className="printer-container">
            {printerImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-printer"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
