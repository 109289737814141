import React, { useState } from "react";

import EnquiryModal from "./modal";

const DetailService = () => {
  const [showModal, setShowModal] = useState(false);

  const [serviceSelected, setServiceSelected] = useState("");

  const services = [
    {
      iconName: "bi bi-window",
      name: "Custom Software Development",
      description:
        "We provide full stack custom software development service for web and Mobile app ensuring responsiveness and scalability at every stage of the development cycle.",
    },
    {
      iconName: "bi bi-code-slash",
      name: "Website Development",
      description:
        "We match unique development requirements of Business to Business as well as Business to Consumer and offer best web development service that accelerate business revenue in the market with our optimized mapped solution.",
    },
    {
      iconName: "bi bi-phone",
      name: "Mobile App Development",
      description:
        "We have passionate and dedicated mobile application developer for create your apps that can create the best Secure Optimal Apps for your business and provide rich features.",
    },
    {
      iconName: "bi bi-back",
      name: "Logo & Graphic Design",
      description:
        "With an understanding of market trends with best color combination and design approaches we deliver best Logo & Graphics design services.",
    },
    {
      iconName: "bi bi-filetype-jsx",
      name: "MERN Stack Development",
      description:
        "We provide dedicated developers for create your Single Page Application using React js. MERN stack development is a full stack development framework with the combination of MongoDB, ExpressJs, ReactJs, and NodeJs.",
    },
    {
      iconName: "bi bi-braces",
      name: "API Services",
      description:
        " We provide custom API services for your Business software. We are able to Create and Integrate Optimized API as per clients requirements and project architecture.",
    },
    // Add more services as needed
  ];

  const openModal = (service) => {
    setShowModal(true);
    setServiceSelected(service);
    console.log("open modal", showModal, serviceSelected);
  };

  return (
    <>
      <section id="detailService" className="detailService section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title" data-aos="zoom-out">
            <h2>Our Services</h2>
            <p>
              Convert an Idea into the Reality, Unlock the capabilities of
              custom software development & product development
            </p>
          </div>

          <div className="row gy-4">
            {services.map((service, index) => (
              <div
                className="col-lg-4 col-md-6"
                key={index}
                data-aos="fade-up"
                data-aos-delay={100 * (index + 1)}
              >
                <div className="service-item  position-relative">
                  <div className="icon">
                    <i className={`${service.iconName}`}></i>
                  </div>
                  <h3>{service.name}</h3>
                  <p>{service.description}</p>
                  <button
                    className="readmore stretched-link"
                    onClick={() => openModal(service.name)}
                  >
                    Enquiry Now <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <EnquiryModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        serviceSelected={serviceSelected}
      />
    </>
  );
};

export default DetailService;
