import React from "react";

const Clients = () => {
  return (
    <section id="clients" className="section-bg">
      <div className="container" data-aos="fade-up">
        <div
          className="row g-0 clients-wrap clearfix"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/HOSPITAL.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/school.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/college.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/corporate.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/government.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="client-logo">
              <img
                src="assets/img/clients/Houses.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
