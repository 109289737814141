import React from "react";

const MVV = () => {
  return (
    <section id="mvv" class="mvv">
      <div class="container">
        <div class="row">
          <div class="col-lg-4" data-aos="fade-right">
            <div class="box">
              <span>Vision</span>
              <p>
                Our vision extends beyond mere technological advancement; it
                encompasses a broader commitment to creating positive social
                impact and driving inclusive growth. We envision a future where
                technology serves as a catalyst for social change, bridging
                gaps, fostering collaboration, and empowering underserved
                communities to participate in the digital economy.
              </p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up">
            <div class="box">
              <span>Mission</span>
              <p>
                Our mission is simple: to empower every user with the tools they
                need to excel in their digital endeavors. Whether you're a
                hardcore gamer seeking the ultimate performance, a creative
                professional in need of precision tools, or a casual user
                looking for reliable and stylish peripherals, we have you
                covered.
              </p>
              {/* <ul>
                <li>Unique Agro Commodities</li>
                <li>Sustainable Practices</li>
                <li>Customer-Centric Approach</li>
              </ul> */}
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-left">
            <div class="box">
              <span>Values</span>
              <p>
                Our values serve as the guiding principles that shape our every
                endeavor. We are driven by a steadfast commitment to innovation,
                constantly pushing the boundaries of what's possible to deliver
                transformative technology solutions. Our integrity forms the
                foundation of our interactions, ensuring that we operate with
                unwavering honesty, transparency, and accountability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MVV;
