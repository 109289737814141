import React, { useEffect, useState } from "react";
// import "../assets/css/style.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const handleDropdownToggle = (e) => {
    if (isMobileNavOpen) {
      setIsMobileNavOpen(false);
    }
  };

  return (
    <header
      id="header"
      className={`fixed-top d-flex align-items-center ${
        isScrolled ? "" : "header-transparent"
      }`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <div className="logo me-auto">
          <Link to="/">
            <img src="/assets/img/RMakLogo4.png" alt="logo" />
          </Link>
        </div>

        <nav
          id="navbar"
          className={`navbar ${isMobileNavOpen ? " navbar-mobile" : ""}`}
        >
          <ul>
            <li>
              <Link
                className={`nav-link ${location.pathname === "/" && "active"}`}
                to="/"
                onClick={handleDropdownToggle}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`nav-link  ${
                  location.pathname === "/about" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/product"
                className={`nav-link  ${
                  location.pathname === "/product" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                Product
              </Link>
            </li>
            <li>
              <Link
                to="/services/software"
                className={`nav-link ${
                  location.pathname === "/services/software" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                Software
              </Link>
            </li>
            <li>
              <Link
                to="/services/security"
                className={`nav-link ${
                  location.pathname === "/services/security" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                Security
              </Link>
            </li>
            {/* <li>
              <Link
                to="/services"
                className={` nav-link ${
                  location.pathname === "/services" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                <span>Services</span> <i className="bi bi-chevron-down"></i>
              </Link>
            </li> */}
            {/* <li className="dropdown">
              <Link
                to="/services"
                className={` nav-link ${
                  location.pathname === "/services" && "active"
                }`}
                onClick={handleDropdownToggle}
              >
                <span>Services</span> <i className="bi bi-chevron-down"></i>
              </Link>
              <ul>
                <li>
                  <Link
                    to="/services/software"
                    className={`nav-link ${
                      location.pathname === "/services/software" && "active"
                    }`}
                    onClick={handleDropdownToggle}
                  >
                    Software
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/security"
                    className={`nav-link ${
                      location.pathname === "/services/security" && "active"
                    }`}
                    onClick={handleDropdownToggle}
                  >
                    Security
                  </Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link
                className={`nav-link ${
                  location.pathname === "/contact" && "active"
                }`}
                to="/contact"
                onClick={handleDropdownToggle}
              >
                Contact
              </Link>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={handleMobileNavToggle}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
