import React from "react";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";

const HeroSection = () => {
  return (
    <>
      <section
        id="hero"
        class="d-flex flex-column justify-content-end align-items-center"
      >
        <div className="container">
          <div className="hero-img" data-aos="zoom-out-left" data-aos-delay="2000">
            <img
              src="/assets/img/home-image-15 copy.png"
              alt="hero-img"
              className="img-fluid"
            />
          </div>

          <div className="hero-info" data-aos="zoom-in" data-aos-delay="100">
            <h2>
              Unleashing
              <br />
              Success through
              <br />
              <span>
                {" "}
                <Typewriter
                  words={["Innovative Solutions"]}
                  loop={true}
                  cursor
                  cursorStyle=""
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <br />
              for a Dynamic World!
            </h2>
            <div>
              <Link to="/about" class="btn-get-started">
                Get Started
              </Link>
              <Link to="/product" className="btn-services">
                Our Services
              </Link>
            </div>
          </div>
        </div>
        <svg
          class="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="wave1">
            <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g class="wave2">
            <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g class="wave3">
            <use href="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>
      </section>
    </>
  );
};

export default HeroSection;
