import React, { useRef, useEffect } from "react";
import Isotope from "isotope-layout";

const SecurityPortfolio = () => {
  const securityPortfolioRef = useRef(null);
  const securityIsotopeRef = useRef(null);

  const biometricImages = [
    {
      src: "/assets/img/Security Devices/Biometric/bio matric machine.jpg",
      alt: "Bio Matric Machine",
    },
    {
      src: "/assets/img/Security Devices/Biometric/Fingerprint-Sensors.png",
      alt: "FingerPrint Sensors",
    },
  ];

  const cctvImages = [
    {
      src: "/assets/img/Security Devices/CCTV Camera/CCTV.jpg",
      alt: "CCTV Camera",
    },
  ];

  const fireAlarmImages = [
    {
      src: "/assets/img/Security Devices/Fire Alarm/FIRE ALARM.webp",
      alt: "Fire Alarm",
    },
  ];

  const scanningImages = [
    {
      src: "/assets/img/hardware/Scanning/barc.jpeg",
      alt: "BarCode",
    },
    {
      src: "/assets/img/hardware/Scanning/Fingerprint-Sensors.png",
      alt: "FingerPrint Sensors",
    },
    {
      src: "/assets/img/hardware/Scanning/barcode.jpg",
      alt: "Bar Code",
    },
    {
      src: "/assets/img/hardware/Scanning/bio matric machine.jpg",
      alt: "Bar Code",
    },
  ];

  const laptopImages = [
    {
      src: "/assets/img/hardware/Laptops/laptop1.jpeg",
      alt: "Laptop",
    },
    {
      src: "/assets/img/hardware/Laptops/laptop2.jpeg",
      alt: "Laptop",
    },
    {
      src: "/assets/img/hardware/Laptops/laptop3.jpeg",
      alt: "Laptop",
    },
  ];

  const desktopImages = [
    {
      src: "/assets/img/hardware/Desktop/DESKTOP C.webp",
      alt: "Desktop",
    },
    {
      src: "/assets/img/hardware/Desktop/DESKTOP.jpg",
      alt: "Desktop",
    },
  ];

  useEffect(() => {
    // Initialize Isotope
    securityIsotopeRef.current = new Isotope(securityPortfolioRef.current, {
      itemSelector: ".portfolio-item",
      layoutMode: "fitRows",
    });

    // Cleanup function
    return () => {
      // Destroy Isotope instance
      securityIsotopeRef.current.destroy();
    };
  }, []);

  const handleFilterClick = (filterValue) => {
    const filterItems = document.querySelectorAll(".portfolio-flters li");
    filterItems.forEach((item) => {
      item?.classList?.remove("filter-active");
    });

    let clickedItem;
    if (filterValue === "*") {
      clickedItem = document.querySelector(".portfolio-flters li.all");
    } else {
      clickedItem = document.querySelector(
        `.portfolio-flters li${filterValue}`
      );
    }
    console.log("clicked", clickedItem, filterValue);
    clickedItem?.classList?.add("filter-active");
    // Apply filter
    securityIsotopeRef.current.arrange({ filter: filterValue });
  };

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title">
          <h2>Our Portfolio</h2>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ul id="portfolio-flters" className="portfolio-flters">
              <li
                onClick={() => handleFilterClick("*")}
                className="filter-active all"
              >
                All
              </li>
              <li
                className="filter-cctv"
                onClick={() => handleFilterClick(".filter-cctv")}
              >
                CCTV Camera
              </li>
              <li
                className="filter-firealarm"
                onClick={() => handleFilterClick(".filter-firealarm")}
              >
                Fire Alarm
              </li>
              <li
                className="filter-biometric"
                onClick={() => handleFilterClick(".filter-biometric")}
              >
                BioMetric System
              </li>
              <li
                className="filter-facedetection"
                onClick={() => handleFilterClick(".filter-facedetection")}
              >
                Face Detection System
              </li>
              <li
                className="filter-digitallock"
                onClick={() => handleFilterClick(".filter-digitallock")}
              >
                Digital Lock System
              </li>
              <li
                className="filter-motiondetector"
                onClick={() => handleFilterClick(".filter-motiondetector")}
              >
                Motion Detector System
              </li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" ref={securityPortfolioRef}>
          {/* CCTV */}
          <div className="cctv-container">
            {cctvImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-cctv"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Fire Alarm */}
          <div className="firealarm-container">
            {fireAlarmImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-firealarm"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* biometric */}
          <div className="biometric-container">
            {scanningImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-biometric"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Face Detection */}
          <div className="facedetection-container">
            {biometricImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-facedetection"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Digital Lock */}
          <div className="digitallock-container">
            {laptopImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-digitallock"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Motion Detector */}
          <div className="motiondetector-container">
            {desktopImages.map((image, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 portfolio-item filter-motiondetector"
              >
                <div className="portfolio-wrap">
                  <img src={image.src} className="img-fluid" alt={image.alt} />
                  <div className="portfolio-info">
                    <h3>{image.alt}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecurityPortfolio;
