import { useState } from "react";
import { storage } from "../../config/firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const useImageUpload = () => {
  const [error, setError] = useState(null);

  const uploadImage = async (files) => {
    try {
      const uploadPromises = files?.map(async (file) => {
        const storageRef = ref(storage, `images/${file?.name}`);

        //   Create file metadata including the content type
        const metadata = {
          contentType: file?.type,
        };

        // Upload the file in the bucket storage
        const snapshot = await uploadBytesResumable(storageRef, file, metadata);
        const downloadURL = await getDownloadURL(snapshot?.ref);
        return downloadURL;
      });

      const imageUrls = await Promise.all(uploadPromises);

      setError(null);
      return imageUrls;
    } catch (error) {
      setError(error.message || "Failed to upload image");
    }
  };

  return { error, uploadImage };
};

export default useImageUpload;
