import React, { useState } from "react";

import EnquiryModal from "./modal";

const HardwareCardFlip = () => {
  const [showModal, setShowModal] = useState(false);
  const [serviceSelected, setServiceSelected] = useState("");

  const openModal = (service) => {
    setShowModal(true);
    setServiceSelected(service);
  };

  return (
    <>
      <section
        id="flipcardServices"
        class="flipcardServices"
        data-aos="fade-up"
      >
        <div class="container">
          <div className="section-title">
            <h2>Our Offerings</h2>
          </div>
          <div class="row" style={{ marginBottom: "50px" }}>
            <div class="col-md-4  " data-aos-delay="100">
              <div class="icon-box icon-box-pink">
                <div class="icon">
                  {/* <i class="bx bx-file"></i> */}
                  <img
                    src="/assets/img/product/learning.png"
                    alt="e-learning"
                  />
                </div>

                <h4 class="title">
                  <a href="">E-Learning Devices</a>
                </h4>

                <p class="description ">
                  We specialize in providing innovative e-learning devices
                  tailored to meet the unique needs of modern education.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> Smart Boards
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> N Computing
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Tabs
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Projector
                    </li>
                  </ul>
                </div>
                <button
                  className="readmore stretched-link"
                  onClick={() => openModal("E-Learning Devices")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>

            <div class="col-md-4  " data-aos-delay="100">
              <div class="icon-box icon-box-cyan">
                <div class="icon">
                  {/* <i class="bx bx-file"></i> */}
                  <img
                    src="/assets/img/product/conference.png"
                    alt="e-learning"
                  />
                </div>

                <h4 class="title">
                  <a href="">Conference Devices</a>
                </h4>

                <p class="description ">
                  We're committed to connecting you with the latest technology
                  tailored to meet your conference needs.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> Projector
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> WebCams
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Microphone
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Speaker
                    </li>
                  </ul>
                </div>
                <a
                  className="readmore stretched-link"
                  onClick={() => openModal("Conference Device")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-md-4  " data-aos-delay="200">
              <div class="icon-box icon-box-green">
                <div class="icon">
                  {/* <i class="bx bx-file"></i> */}
                  <img
                    src="/assets/img/product/scanning.png"
                    alt="e-learning"
                  />
                </div>

                <h4 class="title">
                  <a href="">Scanning Devices</a>
                </h4>

                <p class="description">
                  Our mission is to empower individuals and businesses with
                  advanced scanning technologies, enabling seamless connectivity
                  and productivity in today's fast-paced digital world.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> Finger
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Face
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> BarCode
                    </li>
                  </ul>
                </div>
                <button
                  className="readmore stretched-link"
                  onClick={() => openModal("Scanning Device")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 ">
              <div class="icon-box icon-box-pink">
                <div class="icon">
                  {/* <i class="bx bxl-dribbble"></i> */}
                  <img src="/assets/img/product/tablets.png" alt="tablets" />
                </div>
                <h4 class="title">
                  <a href="">Tablets</a>
                </h4>
                <p class="description">
                  For personal use, business applications, or educational
                  purposes, our offering tablets are designed to empower users
                  with seamless performance and reliability.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> Samsung
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> iBall
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Lenovo
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> HP
                    </li>
                  </ul>
                </div>
                <a
                  className="readmore stretched-link"
                  onClick={() => openModal("Tablets")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-md-4  " data-aos-delay="100">
              <div class="icon-box icon-box-cyan">
                <div class="icon">
                  {/* <i class="bx bx-file"></i> */}
                  <img src="/assets/img/product/laptop.png" alt="tablets" />
                </div>

                <h4 class="title">
                  <a href="">Laptops & Desktops</a>
                </h4>

                <p class="description">
                  We specialize in providing top-notch laptops & desktops to
                  meet all your computing needs, from professionals seeking
                  powerful hardware for work to tech enthusiasts in search of
                  the latest gadgets.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> HP
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Dell
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Lenovo
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Acer
                    </li>
                  </ul>
                </div>
                <a
                  className="readmore stretched-link"
                  onClick={() => openModal("Laptops & Desktops")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="col-md-4  " data-aos-delay="200">
              <div class="icon-box icon-box-green">
                <div class="icon">
                  {/* <i class="bx bx-file"></i> */}
                  <img src="/assets/img/product/desktop.png" alt="tablets" />
                </div>

                <h4 class="title">
                  <a href="">Printers</a>
                </h4>

                <p class="description">
                  With a focus on innovation and reliability, our products
                  empower individuals and businesses to harness the full
                  potential of technology.
                </p>
                <div className="check-list">
                  <ul>
                    <li>
                      <i class="bi bi-check2-circle"></i> HP
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Epson
                    </li>
                    <li>
                      <i class="bi bi-check2-circle"></i> Canon
                    </li>
                  </ul>
                </div>
                <a
                  className="readmore stretched-link"
                  onClick={() => openModal("Printers")}
                >
                  Enquiry Now <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EnquiryModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        serviceSelected={serviceSelected}
      />
    </>
  );
};

export default HardwareCardFlip;
