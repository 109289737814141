import React from "react";

import Footer from "../components/footer";
import HeroSection from "../components/herosection";

const Home = () => {
  return (
    <>
      <HeroSection />
      {/* 
      <section id="about" class="newabout">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div className="section-title">
            <h2>About</h2>
            <p>Who we are</p>
          </div>
          <div class="row align-items-xl-center gy-5">
            <div class="col-xl-5 content">
              <h2>Ducimus rerum libero reprehenderit cumque</h2>
              <p>
                Ipsa sint sit. Quis ducimus tempore dolores impedit et dolor
                cumque alias maxime. Enim reiciendis minus et rerum hic non.
                Dicta quas cum quia maiores iure. Quidem nulla qui assumenda
                incidunt voluptatem tempora deleniti soluta.
              </p>
              <Link to="/about" class="read-more">
                <span>Read More</span>
                <i class="bi bi-arrow-right"></i>
              </Link>
            </div>
            <div class="col-xl-7">
              <div class="row gy-4 icon-boxes">
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <div class="icon-box">
                    <i class="bi bi-buildings"></i>
                    <h3>Eius provident</h3>
                    <p>
                      Magni repellendus vel ullam hic officia accusantium ipsa
                      dolor omnis dolor voluptatem
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
                  <div class="icon-box">
                    <i class="bi bi-clipboard-pulse"></i>
                    <h3>Rerum aperiam</h3>
                    <p>
                      Autem saepe animi et aut aspernatur culpa facere. Rerum
                      saepe rerum voluptates quia
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
                  <div class="icon-box">
                    <i class="bi bi-command"></i>
                    <h3>Veniam omnis</h3>
                    <p>
                      Omnis perferendis molestias culpa sed. Recusandae quas
                      possimus. Quod consequatur corrupti
                    </p>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
                  <div class="icon-box">
                    <i class="bi bi-graph-up-arrow"></i>
                    <h3>Delares sapiente</h3>
                    <p>
                      Sint et dolor voluptas minus possimus nostrum. Reiciendis
                      commodi eligendi omnis quideme lorenda
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container">
          <div className="section-title" data-aos="zoom-out">
            <h2>We Are</h2>
            <p>Guided by passion, driven by purpose</p>
          </div>

          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-3" data-aos="zoom-in">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                href="#tab-1"
              >
                <i className="ri-gps-line"></i>
                <h4 className="d-none d-lg-block">
                  Modi sit est dela pireda nest
                </h4>
              </a>
            </li>
            <li
              className="nav-item col-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                <i className="ri-body-scan-line"></i>
                <h4 className="d-none d-lg-block">
                  Unde praesenti mara setra le
                </h4>
              </a>
            </li>
            <li
              className="nav-item col-3"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                <i className="ri-sun-line"></i>
                <h4 className="d-none d-lg-block">
                  Pariatur explica nitro dela
                </h4>
              </a>
            </li>
            <li
              className="nav-item col-3"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                <i className="ri-store-line"></i>
                <h4 className="d-none d-lg-block">Nostrum qui dile node</h4>
              </a>
            </li>
          </ul>

          <div className="tab-content" data-aos="fade-up">
            <div className="tab-pane active show" id="tab-1">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Voluptatem dignissimos provident quasi corporis voluptates
                    sit assumenda.
                  </h3>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-2">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Neque exercitationem debitis soluta quos debitis quo
                    mollitia officia est
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Provident
                      mollitia neque rerum asperiores dolores quos qui a. Ipsum
                      neque dolor voluptate nisi sed.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-3">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Voluptatibus commodi ut accusamus ea repudiandae ut autem
                    dolor ut assumenda
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Provident
                      mollitia neque rerum asperiores dolores quos qui a. Ipsum
                      neque dolor voluptate nisi sed.
                    </li>
                  </ul>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-3.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-4">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Omnis fugiat ea explicabo sunt dolorum asperiores sequi
                    inventore rerum
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/img/features-4.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cta" className="cta">
        <div className="container">
          <div className="row" data-aos="zoom-out">
            <div className="col-lg-9 text-center text-lg-start">
              <h3>Call To Action</h3>
              <p>
                {" "}
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              <a className="cta-btn align-middle" href="#">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container">
          <div className="section-title" data-aos="zoom-out">
            <h2>Services</h2>
            <p>What we do offer</p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="zoom-in-left">
                <div className="icon">
                  <i
                    className="bi bi-briefcase"
                    style={{ color: "#ff689b" }}
                  ></i>
                </div>
                <h4 className="title">
                  <a href="">Lorem Ipsum</a>
                </h4>
                <p className="description">
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi sint occaecati cupiditate non provident
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="100"
              >
                <div className="icon">
                  <i className="bi bi-book" style={{ color: "#e9bf06" }}></i>
                </div>
                <h4 className="title">
                  <a href="">Dolor Sitema</a>
                </h4>
                <p className="description">
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat tarad limino ata
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-5 mt-lg-0 ">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="200"
              >
                <div className="icon">
                  <i
                    className="bi bi-card-checklist"
                    style={{ color: "#3fcdc7" }}
                  ></i>
                </div>
                <h4 className="title">
                  <a href="">Sed ut perspiciatis</a>
                </h4>
                <p className="description">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="300"
              >
                <div className="icon">
                  <i
                    className="bi bi-binoculars"
                    style={{ color: "#41cf2e" }}
                  ></i>
                </div>
                <h4 className="title">
                  <a href="">Magni Dolores</a>
                </h4>
                <p className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-5">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="400"
              >
                <div className="icon">
                  <i className="bi bi-globe" style={{ color: "#d6ff22" }}></i>
                </div>
                <h4 className="title">
                  <a href="">Nemo Enim</a>
                </h4>
                <p className="description">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="500"
              >
                <div className="icon">
                  <i className="bi bi-clock" style={{ color: "#4680ff" }}></i>
                </div>
                <h4 className="title">
                  <a href="">Eiusmod Tempor</a>
                </h4>
                <p className="description">
                  Et harum quidem rerum facilis est et expedita distinctio. Nam
                  libero tempore, cum soluta nobis est eligendi
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" class="portfolio">
        <div class="container">
          <div class="section-title" data-aos="zoom-out">
            <h2>Portfolio</h2>
            <p>What we've done</p>
          </div>

          <ul
            id="portfolio-flters"
            class="d-flex justify-content-end"
            data-aos="fade-up"
          >
            <li data-filter="*" class="filter-active">
              All
            </li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>
          </ul>

          <div class="row portfolio-container" data-aos="fade-up">
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-1.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>App 1</h4>
                <p>App</p>
                <a
                  href="assets/img/portfolio/portfolio-1.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="App 1"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-2.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <a
                  href="assets/img/portfolio/portfolio-2.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Web 3"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-3.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>App 2</h4>
                <p>App</p>
                <a
                  href="assets/img/portfolio/portfolio-3.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="App 2"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-4.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Card 2</h4>
                <p>Card</p>
                <a
                  href="assets/img/portfolio/portfolio-4.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Card 2"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-5.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Web 2</h4>
                <p>Web</p>
                <a
                  href="assets/img/portfolio/portfolio-5.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Web 2"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-6.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>App 3</h4>
                <p>App</p>
                <a
                  href="assets/img/portfolio/portfolio-6.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="App 3"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-7.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Card 1</h4>
                <p>Card</p>
                <a
                  href="assets/img/portfolio/portfolio-7.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Card 1"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-8.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Card 3</h4>
                <p>Card</p>
                <a
                  href="assets/img/portfolio/portfolio-8.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Card 3"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-img">
                <img
                  src="assets/img/portfolio/portfolio-9.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <a
                  href="assets/img/portfolio/portfolio-9.jpg"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox preview-link"
                  title="Web 3"
                >
                  <i class="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  class="details-link"
                  title="More Details"
                >
                  <i class="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials" class="testimonials">
        <div class="container">
          <div class="section-title" data-aos="zoom-out">
            <h2>Testimonials</h2>
            <p>What they are saying about us</p>
          </div>

          <div
            class="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="assets/img/testimonials/testimonials-1.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-item">
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse
                    labore quem cillum quid cillum eram malis quorum velit fore
                    eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="assets/img/testimonials/testimonials-2.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-item">
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Enim nisi quem export duis labore cillum quae magna enim
                    sint quorum nulla quem veniam duis minim tempor labore quem
                    eram duis noster aute amet eram fore quis sint minim.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="assets/img/testimonials/testimonials-3.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-item">
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                    multos export minim fugiat minim velit minim dolor enim duis
                    veniam ipsum anim magna sunt elit fore quem dolore labore
                    illum veniam.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="assets/img/testimonials/testimonials-4.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="testimonial-item">
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam culpa
                    fore nisi cillum quid.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="assets/img/testimonials/testimonials-5.jpg"
                    class="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Home;
